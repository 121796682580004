import React, { useState, useEffect } from "react"
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Row, Container, Col } from 'react-bootstrap';
import _ from "lodash"
import logoBlack from "../images/logo-dark.svg";

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Seo from "../components/seo"
import GenerateLink from "../components/common/site/generate-link"
import { ABOUT_PAGE_URL, AREA_GUIDE_PAGE_URL, TESTIMONIALS_PAGE_URL, GIVING_BACK_PAGE_URL, BRANCHES_PAGE_URL } from "../components/common/site/constants"

const Sitemap = () => {

  var buying_guides = []
  var selling_guides = []
  var landlord_guides = []
  var renting_guides = []

  const alldata = useStaticQuery(graphql`
  query {
    allBranches(
      filter: {publish: {eq: true}, brand_id: {eq: "wk"}}
      sort: {order: ASC, fields: branch_name}
    ) {
      edges {
        node {
          branch_name
          slug
        }
      }
    }
    glstrapi {
      menusHeaders(sort: "Sort:asc") {
        id
        Label
        Link {
          id
        }
        Sort
        Submenu_Search_Title
        In_Submenu_Show_Search
        Add_Sub_Menus {
          Title
          Add_here {
            Submenu_Label
            Submenu_Link {
              Alias
              id
            }
          }
        }
      }
      areaGuides(
        publicationState: LIVE
        where: {Publish: true}
        sort: "Name:asc"
      ) {
        id
        Name
        URL
      }

      guides(publicationState: LIVE) {
        id
        Title
        URL
        Category {
          id
          Name
          Sort
          URL
          Details_Page_URL {
            Main_Parent {
              URL
            }
            Secondary_URL
            Sub_Parent {
              URL
            }
            Sub_Parent_Secondary {
              URL
            }
            URL
          }
        }
    }

    }
  }
`);

  const menus = alldata.glstrapi.menusHeaders;
  const areaguides = alldata.glstrapi.areaGuides;
  const allguides = alldata.glstrapi.guides;
  const allbranches = alldata.allBranches.edges

  buying_guides = allguides.filter(item => {
    let main_category = item.Category.filter(cat => _.includes(cat, 'buying-guide'));
    return main_category.length > 0
  });

  selling_guides = allguides.filter(item => {
    let main_category = item.Category.filter(cat => _.includes(cat, 'selling-guide'));
    return main_category.length > 0
  });

  landlord_guides = allguides.filter(item => {
    let main_category = item.Category.filter(cat => _.includes(cat, 'landlords-guide'));
    return main_category.length > 0
  });

  renting_guides = allguides.filter(item => {
    let main_category = item.Category.filter(cat => _.includes(cat, 'renting-guide'));
    return main_category.length > 0
  });

  return (
    <>
      <Header />
      <Seo title="Wards | Sitemap" />
      <div className="wrapper collection-landing">
        <Container>
          <Row>
            <Col lg={12} className="sitemap-page">
              <h1>Sitemap</h1>
              <ul className="sitemap-main-list">
                {menus.map((node, i) => {
                  var main_label = node.Label
                  return <React.Fragment key={i}>
                    {node.Add_Sub_Menus.length === 0 &&
                      <li className="li-main">
                        {node.Link ?
                          <GenerateLink link={node.Link} class="title" label={node.Label}>{node.Label}</GenerateLink> :
                          <>{node.Label}</>
                        }
                      </li>
                    }

                    {node.Add_Sub_Menus.length !== 0 &&
                      <li className="li-main">
                        {node.Link ?
                          <GenerateLink link={node.Link} class="title" label={node.Label}>{node.Label}</GenerateLink> :
                          <>{node.Label}</>
                        }
                        <ul className="sub-menu sitemap-sub-menu-list">
                          {node.Add_Sub_Menus && node.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                            return <React.Fragment>
                              {Add_Sub_Menus && Add_Sub_Menus.Add_here.map((menus, i) => {
                                var catname = ''
                                if (menus.Submenu_Link?.Alias == "buying-guides") {
                                  catname = buying_guides
                                }
                                else if (menus.Submenu_Link?.Alias == "selling-guides") {
                                  catname = selling_guides
                                }
                                else if (menus.Submenu_Link?.Alias == "landlords-guides") {
                                  catname = landlord_guides
                                }
                                else if (menus.Submenu_Link?.Alias == "renting-guide") {
                                  catname = renting_guides
                                }
                                return (
                                  <React.Fragment>
                                    {menus.Submenu_Label != "Full Market Launch" && menus.Submenu_Label != "Buying" &&
                                      <li key={i} className='sub-li-list'>
                                        <GenerateLink link={menus.Submenu_Link}>{menus.Submenu_Label}</GenerateLink>
                                        {(menus.Submenu_Link?.Alias == "buying-guides" || menus.Submenu_Link?.Alias == "selling-guides" || menus.Submenu_Link?.Alias == "landlords-guides" || menus.Submenu_Link?.Alias == "renting-guide") && menus.Submenu_Label != "Our Property Guides" ?
                                          <ul className="sub-menu sitemap-sub-menu-list">
                                            {catname.map((item, i) => {

                                              let PageURL = item.Category[0].Details_Page_URL

                                              let URL = PageURL.Secondary_URL ? PageURL.Secondary_URL : PageURL.URL+'/'

                                              if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent == null) && (PageURL.Sub_Parent_Secondary == null)) {
                                                URL = PageURL.Main_Parent.URL + '/' + URL+'/'
                                              }

                                              else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null) && (PageURL.Sub_Parent_Secondary == null)) {
                                                URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' + URL+'/'
                                              }

                                              else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null) && (PageURL.Sub_Parent_Secondary != null)) {
                                                URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' + PageURL.Sub_Parent_Secondary.URL + '/' + URL+'/'
                                              }
                                              return (
                                                <li>
                                                  <Link to={`/${URL}/${item.URL}/`}>{item.Title}</Link>
                                                </li>
                                              )
                                            }
                                            )}
                                          </ul> : menus.Submenu_Link?.Alias == "branchfinder" ? <ul className="sub-menu sitemap-sub-menu-list">{allbranches ? allbranches.map(({node}, i) => (
                                            <li>
                                              <Link to={`${BRANCHES_PAGE_URL.alias}/estate-agents-in-${node.slug}/`}>{node.branch_name}</Link>
                                            </li>
                                          )) : 'please wait'}</ul> : ''}
                                      </li>
                                    }
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment>
                          })}
                        </ul>
                      </li>
                    }
                  </React.Fragment>
                })}
                <li className="li-main">
                  <Link to={ABOUT_PAGE_URL.alias} className="title">About Us</Link>
                  <ul className="sub-menu sitemap-sub-menu-list">
                    <li>
                      <Link to={GIVING_BACK_PAGE_URL.alias}>Giving Back</Link>
                    </li>
                    <li>
                      <Link to={TESTIMONIALS_PAGE_URL.alias}>Testimonials</Link>
                    </li>
                    <li>
                      <Link to={AREA_GUIDE_PAGE_URL.alias}>Area Guides</Link>
                      <ul className="sub-menu sitemap-sub-menu-list">
                        {areaguides.map((areas, i) => (
                          <li>
                            <Link to={`${AREA_GUIDE_PAGE_URL.alias}${areas.URL}/`}>{areas.Name}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default Sitemap
